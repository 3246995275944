import {
  Button,
  Card,
  ControlGroup,
  FormGroup,
  H4,
  HTMLSelect,
  InputGroup,
} from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import FileUploader from '../FileUploader';
import Firms from '../Firms';
import HtmlGenerator from '../HtmlGenerator/HtmlGenerator';
import './InputForm.css';
import Constants from '../../constants';

function InputForm() {
  // STATE
  const [selectedFirm, setSelectedFirm] = useState(Firms.items[0]);
  const [selectedFirmLocation, setSelectedFirmLocation] = useState(0);
  const [selectedBanner, setSelectedBanner] = useState(
    Firms.items[0].banner[0]
  );
  const [selectedAvatar, setSelectedAvatar] = useState('/uploads/default.png');
  const [name, setName] = useState('');
  const [position, setPosition] = useState('');
  const [isUploadUnlocked, setIsUploadUnlocked] = useState(false);
  const [isLocationShown, setIsLocationShown] = useState(false);
  const [imageList, setImageList] = useState(['']);

  const getFileList = async () => {
    const response = await axios.get(
      `${Constants.APP_URL}/upload.php?filelist`
    );
    setImageList(
      response.data.data.length > 0
        ? response.data.data
        : ['Noch keine Bilder verfügbar']
    );
  };

  // Get Image List on iniital component mount
  useEffect(() => {
    getFileList();
  }, []);

  // Update List if file was uploaded
  const fileUploadedHandler = () => {
    getFileList();
  };

  const selectChangeHandler = (item) => {
    setSelectedFirm(item);
    setSelectedBanner(item.banner[0]);
    setIsLocationShown(item.locations.length > 1);
  };

  const locationSelectHandler = (e) => {
    setSelectedFirmLocation(e.target.selectedIndex);
  };

  return (
    <div id='input-form'>
      <Card elevation='2'>
        <div className='center'>
          <img src={selectedFirm.logo} alt='logo' className='logo'></img>
          <H4 className='muted-text'>Signature Creator</H4>
        </div>
        <FormGroup>
          <ControlGroup>
            <Select
              id='select-firm'
              filterable={false}
              items={Firms.items}
              onItemSelect={selectChangeHandler}
              itemRenderer={Firms.itemRenderer}
            >
              <Button text={selectedFirm.name} icon='office' />
            </Select>
            <HTMLSelect
              style={{ visibility: isLocationShown ? 'visible' : 'hidden' }}
              iconProps={{
                style: { visibility: isLocationShown ? 'visible' : 'hidden' },
              }}
              options={selectedFirm.locations.map((loc) => loc.company)}
              onChange={locationSelectHandler}
            />
          </ControlGroup>
        </FormGroup>
        <FormGroup label='Vor- und Zuname' labelFor='input-name'>
          <InputGroup
            id='input-name'
            onChange={(e) => setName(e.target.value)}
          />
        </FormGroup>
        <FormGroup label='Position' labelFor='input-position'>
          <InputGroup
            id='input-position'
            onChange={(e) => setPosition(e.target.value)}
          />
        </FormGroup>
        <FormGroup label='Email' labelFor='input-email'>
          <InputGroup
            type='mail'
            id='input-email'
            onChange={(e) => {
              let newSelectedFirm = { ...selectedFirm };
              newSelectedFirm.locations[selectedFirmLocation].mail =
                e.target.value;
              setSelectedFirm(newSelectedFirm);
            }}
          />
        </FormGroup>
        <FormGroup label='Telefon' labelFor='input-tel'>
          <InputGroup
            id='input-tel'
            onChange={(e) => {
              let newSelectedFirm = { ...selectedFirm };
              newSelectedFirm.locations[selectedFirmLocation].phone =
                e.target.value;
              setSelectedFirm(newSelectedFirm);
            }}
          />
        </FormGroup>
        <FormGroup label='Avatarbild auswählen' labelFor='select-avatar'>
          <ControlGroup>
            <HTMLSelect
              id='select-avatar'
              options={imageList}
              onChange={(e) => setSelectedAvatar('/uploads/' + e.target.value)}
            />

            <FileUploader
              isUnlocked={isUploadUnlocked}
              onUnlock={() => {
                setIsUploadUnlocked(true);
              }}
              onFileUploaded={fileUploadedHandler}
            />
          </ControlGroup>
        </FormGroup>
        {/* Selection of  Banner*/}
        <span className='m-3'>Banner auswählen</span>
        <div className='banner-container'>
          <Card
            elevation='2'
            className='banner-card'
            interactive
            onClick={(e) => setSelectedBanner(selectedFirm.banner[0])}
          >
            <img
              src={selectedFirm.banner[0]}
              alt='banner1'
              className='banner'
            />
          </Card>
          <Card
            elevation='2'
            className='banner-card'
            interactive
            onClick={(e) => setSelectedBanner(selectedFirm.banner[1])}
          >
            <img
              src={selectedFirm.banner[1]}
              alt='banner2'
              className='banner'
            />
          </Card>
          <Card
            elevation='2'
            className='banner-card'
            interactive
            onClick={(e) => setSelectedBanner(selectedFirm.banner[2])}
          >
            <img
              src={selectedFirm.banner[2]}
              alt='banner3'
              className='banner'
            />
          </Card>
        </div>
        <div className='preview' style={{ marginTop: '1rem' }}>
          <span>Vorschau:</span>
          <HtmlGenerator
            name={name}
            position={position}
            banner={selectedBanner}
            logo={selectedFirm.logo}
            avatar={selectedAvatar}
            location={selectedFirm.locations[selectedFirmLocation]}
          />
        </div>
      </Card>
    </div>
  );
}

export default InputForm;
