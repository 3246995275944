import React from 'react';
import './Container.css';

function Container(props) {
  return (
    <div id="container" className="bp3-dark">
      {props.children}
    </div>
  );
}

export default Container;
