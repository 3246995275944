import Container from "./components/Container/Container";
import InputForm from './components/InputForm/InputForm'

function App() {
  return (
    <Container>
      <InputForm/>
    </Container>
  );
}

export default App;
