import React, { useState } from 'react';
import './HtmlGenerator.css';
import locationIconLight from '../../assets/icons/location-light.png';
// import faxIconLight from '../../assets/icons/fax-light.png';
import mailIconLight from '../../assets/icons/mail-light.png';
import webIconLight from '../../assets/icons/web-light.png';
import phoneIconLight from '../../assets/icons/phone-light.png';
import { Button, FormGroup } from '@blueprintjs/core';
import Constants from '../../constants';

const getHtmlTemplate = (data) => {
  return `
  <body style="margin: 0;">
    <div style="display: flex; flex-direction: row;font-family: Arial, Helvetica, sans-serif;position: relative">
        <div style="height: 250px; width: 320px;
        background-size: cover;
        background-position: center;
        background-image: url('${Constants.APP_URL + data.banner}')">
        </div>
        <div style="display: flex; flex-direction: column;">
            <div style="display: flex; flex-direction: row; align-items: center; margin-bottom: auto; padding: 1.3rem; padding-right:0; width: 365px;margin-right: 1.3rem;">
                <div style="font-size: 1.5em; margin-right: 1em;">
                    <span style="color: #6a6a6a; font-weight: 300;">${
                      String(data.name).split(' ')[0] || 'Vorname'
                    }</span>
                    <span style="color: #6a6a6a; font-weight: 700;">${
                      data.name.split(' ')[1] || 'Nachname'
                    }</span>
                    <br>
                    <div style="font-size: 0.4em;color: #949494;">
                        <span style="letter-spacing: 0.3em;">${
                          data.position.toUpperCase() || 'POSITION'
                        }</span>
                    </div>
                </div>
                <div
                    style="margin-left:auto;border-radius: 50%; height: 75px; width: 75px;
                     background-image: url('${
                       Constants.APP_URL + data.avatar
                     }');
                     background-size: cover;
                     background-repeat: no-repeat;
                     position: absolute;
                    left: 620px;
                    top: 12px;">
                </div>
            </div>
            <div style="display: flex">
              <div style="color:#6a6a6a; padding: 1.3rem 1.3rem .5rem 1.3rem; font-size: 0.7em; height: auto;">
                  <div style="display: flex; flex-direction: row; align-items: center;">
                    <img src="${locationIconLight}" style="filter: brightness(0.5);height: 1.5em; margin-right: 0.5em;"></img>
                    <span>
                    ${data.location.street}
                    <br>
                    ${data.location.city}
                    </span>
                  </div>
                  <div style="display: flex; flex-direction: row; align-items: center;">
                    <img src="${phoneIconLight}" style="filter: brightness(0.5);height: 1.5em; margin-right: 0.5em;"></img>
                    <a href="tel:${data.location.phone}" style="color: inherit">
                      ${data.location.phone}
                    </a>
                  </div>
                  <div style="display: flex; flex-direction: row; align-items: center;">
                    <img src="${mailIconLight}" style="filter: brightness(0.5);height: 1.5em; margin-right: 0.5em;"></img>
                    <a href="mailto:${
                      data.location.mail
                    }" style="color: inherit">
                      ${data.location.mail}
                    </a>
                  </div>
                  <div style="display: flex; flex-direction: row; align-items: center;">
                    <img src="${webIconLight}" style="filter: brightness(0.5);height: 1.5em; margin-right: 0.5em;"></img>
                    <a href="https://${
                      data.location.web
                    }" style="color: inherit">
                    ${data.location.web}
                    </a>
                  </div>
                  <div style="margin-top: 1rem; font-size: .7em;">
                  ${data.location.bank ? data.location.bank : ''}
                  </div>
              </div>
              <img src="${data.logo}" style="height: 2.5rem;
              position: absolute;
                    top: 175px;
                    left: 570px;""/>
            </div>
        </div>
    </div>
</body>
  `;
};

function HtmlGenerator(props) {
  const [clipboardButtonText, setClipboardButtonText] =
    useState('in Zwischenablage');

  const clipboardSuccessCallback = () => {
    setClipboardButtonText('Kopiert.');
    setTimeout(() => {
      setClipboardButtonText('in Zwischenablage');
    }, 1.5 * 1000);
  };

  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
    clipboardSuccessCallback();
    document.body.removeChild(textArea);
  }

  function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        console.log('Async: Copying to clipboard was successful!');
        clipboardSuccessCallback();
      },
      function (err) {
        console.error('Async: Could not copy text: ', err);
      }
    );
  }

  const html = getHtmlTemplate({
    name: props.name,
    position: props.position,
    avatar: props.avatar,
    logo: props.logo,
    location: props.location,
    banner: props.banner,
  });

  return (
    <div>
      <div
        className='html-generator'
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      ></div>
      <FormGroup>
        <Button
          icon='clipboard'
          onClick={() => {
            copyTextToClipboard(html);
          }}
        >
          {clipboardButtonText}
        </Button>
      </FormGroup>
    </div>
  );
}

export default HtmlGenerator;
