import React, { useState } from 'react';
import crypto from 'crypto';
import './FileUploader.css';
import {
  FormGroup,
  FileInput,
  Button,
  Dialog,
  InputGroup,
  Classes,
  ControlGroup,
} from '@blueprintjs/core';
import axios from 'axios';
import Constants from '../../constants';

const PASSWORD_HASH = '99ba1e97cae8c3290961f60e361bd2d1';

function FileUploader(props) {
  const [selectedFileName, setSelectedFileName] = useState('Datei Wählen...');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [passwordInput, setPasswordInput] = useState('');
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [isShaking, setIsShaking] = useState(false);

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const passwordClickHandler = () => {
    const hash = crypto.createHash('MD5').update(passwordInput).digest('hex');
    if (hash === PASSWORD_HASH) {
      // Password is correct
      toggleDialog();
      setIsUnlocked(true);
    } else {
      // Shake that input
      setIsShaking(true);
      setTimeout(() => {
        setIsShaking(false);
      }, 1 * 1000);
    }
  };

  const onFileChangeHandler = (event) => {
    const file = event.target.files[0];
    setSelectedFileName(file.name);
    const data = new FormData();
    data.append('file', file);
    axios.post(`${Constants.APP_URL}/upload.php`, data, {}).then((res) => {
      console.log(res);
      props.onFileUploaded();
    });
  };

  if (isUnlocked)
    return (
      <div id='file-uploader'>
        <FormGroup>
          <FileInput
            fill={true}
            text={selectedFileName}
            onInputChange={onFileChangeHandler}
          />
        </FormGroup>
      </div>
    );
  else
    return (
      <FormGroup>
        <Button onClick={toggleDialog} icon='lock'>
          Bildupload Freischalten
        </Button>
        <Dialog
          isOpen={isDialogOpen}
          autoFocus={true}
          canEscapeKeyClose={true}
          isCloseButtonShown={true}
          usePortal={true}
          title='Passwort erforderlich'
          onClose={toggleDialog}
          className='bp3-dark'
        >
          <div className={Classes.DIALOG_BODY}>
            <div className='dialog-text'>
              Zum Freischalten der Upload-Funktion bitte das Kennwort eingeben.
            </div>
            <ControlGroup className={isShaking ? 'shake' : ''}>
              <InputGroup
                type='password'
                onChange={(e) => setPasswordInput(e.target.value)}
                onKeyDown={(key) => {
                  console.log('key.code', key.code === 'Enter');
                  if (key.code === 'Enter') passwordClickHandler();
                }}
              />
              <Button id='password-button' onClick={passwordClickHandler}>
                Ok
              </Button>
            </ControlGroup>
          </div>
        </Dialog>
      </FormGroup>
    );
}

export default FileUploader;
