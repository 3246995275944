import { MenuItem } from '@blueprintjs/core';
// Logo import
import aluxusLogo from '../../assets/logos/aluxus_logo.png';
import stargaragenLogo from '../../assets/logos/stargaragen_logo.png';
import stargrillsLogo from '../../assets/logos/stargrills_logo.png';
import starrepairLogo from '../../assets/logos/starrepair_logo.png';
import tischlereirubischLogo from '../../assets/logos/tischlereirubisch_logo.png';
import windaxLogo from '../../assets/logos/windax_logo.png';
// Banner import
import aluxusBanner1 from '../../assets/banner/aluxus_1.jpg';
import aluxusBanner2 from '../../assets/banner/aluxus_2.jpg';
import aluxusBanner3 from '../../assets/banner/aluxus_3.jpg';
import stargaragenBanner1 from '../../assets/banner/stargaragen_1.jpg';
import stargaragenBanner2 from '../../assets/banner/stargaragen_2.jpg';
import stargaragenBanner3 from '../../assets/banner/stargaragen_3.jpg';
import stargrillsBanner1 from '../../assets/banner/stargrills_1.jpg';
import stargrillsBanner2 from '../../assets/banner/stargrills_2.jpg';
import stargrillsBanner3 from '../../assets/banner/stargrills_3.jpg';
import starrepairBanner1 from '../../assets/banner/starrepair_1.jpg';
import starrepairBanner2 from '../../assets/banner/starrepair_2.jpg';
import starrepairBanner3 from '../../assets/banner/starrepair_3.jpg';
import tischlereirubischBanner1 from '../../assets/banner/tischlereirubisch_1.jpg';
import tischlereirubischBanner2 from '../../assets/banner/tischlereirubisch_2.jpg';
import tischlereirubischBanner3 from '../../assets/banner/tischlereirubisch_3.jpg';
import windaxBanner1 from '../../assets/banner/windax_1.jpg';
import windaxBanner2 from '../../assets/banner/windax_2.jpg';
import windaxBanner3 from '../../assets/banner/windax_3.jpg';

const firms = [
  {
    id: 1,
    name: 'Aluxus',
    logo: aluxusLogo,
    banner: [aluxusBanner1, aluxusBanner2, aluxusBanner3],
    locations: [
      {
        company: 'Aluxus',
        street: 'Fritz-Baum-Allee 8',
        city: '47506 Neukirchen-Vluyn',
        phone: '02845-9493000',
        fax: '02845-3809989',
        mail: 'info@aluxus.de',
        web: 'www.aluxus.de',
        bank: 'Sparkasse am Niederrhein | IBAN: DE21354500001101073367 | BIC: WELADED1MOR | USt-IdNr. DE341744964',
      },
    ],
  },
  {
    id: 2,
    name: 'Stargrills',
    logo: stargrillsLogo,
    banner: [stargrillsBanner1, stargrillsBanner2, stargrillsBanner3],
    locations: [
      {
        company: 'Stargrills',
        street: 'Am Schankweiler 16',
        city: '47661 Issum D',
        phone: '02835-4499599',
        fax: '',
        mail: 'info@stargrills.de',
        web: 'www.stargrills.de',
      },
    ],
  },
  {
    id: 3,
    name: 'Stargaragen',
    logo: stargaragenLogo,
    banner: [stargaragenBanner1, stargaragenBanner2, stargaragenBanner3],
    locations: [
      {
        company: 'Standort Rheinberg',
        street: 'Tekkenhof 7',
        city: '47495 Rheinberg',
        phone: '02845-9493003',
        fax: '',
        mail: 'info@stargaragen.de',
        web: 'www.stargaragen.de',
      },
      {
        company: 'Standort Issum',
        street: 'Am Schwankweiler 16',
        city: '47661 Issum',
        phone: '02843–1761291',
        fax: '',
        mail: 'info@stargaragen.de',
        web: 'www.stargaragen.de',
      },
      {
        company: 'Standort Neukirchen-Vluyn',
        street: 'Fritz-Baum-Allee 8',
        city: '47506 Neukirchen-Vluyn',
        phone: '02843–1761291',
        fax: '',
        mail: 'info@stargaragen.de',
        web: 'www.stargaragen.de',
      },
    ],
  },
  {
    id: 4,
    name: 'Starrepair',
    logo: starrepairLogo,
    banner: [starrepairBanner1, starrepairBanner2, starrepairBanner3],
    locations: [
      {
        company: 'Starrepair',
        street: 'Heidberghof 6',
        city: '47495 Rheinberg',
        phone: '02843-9586215',
        fax: '',
        mail: 'inbox@starrepair.de',
        web: 'www.starrepair.de',
      },
    ],
  },
  {
    id: 5,
    name: 'Tischlerei Rubisch',
    logo: tischlereirubischLogo,
    banner: [
      tischlereirubischBanner1,
      tischlereirubischBanner2,
      tischlereirubischBanner3,
    ],
    locations: [
      {
        company: 'Tischlerei Kai Rubisch',
        street: 'Heidberghof 6',
        city: '47495 Rheinberg',
        phone: '02843-9586215',
        fax: '02843-9586216',
        mail: 'info@tischlerei-rubisch.de',
        web: 'www.tischlerei-rubisch.de',
      },
    ],
  },
  {
    id: 6,
    name: 'Windax',
    logo: windaxLogo,
    banner: [windaxBanner1, windaxBanner2, windaxBanner3],
    locations: [
      {
        company: 'Windax',
        street: 'Am Schankweiler 16',
        city: '47661 Issum',
        phone: '02843-9586217',
        fax: '02843-7159977',
        mail: 'info@windax.de',
        web: 'www.windax.de',
      },
    ],
  },
];

const renderFirm = (firm, { handleClick, modifiers }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={firm.id}
      onClick={handleClick}
      text={firm.name}
    />
  );
};

export default {
  items: firms,
  itemRenderer: renderFirm,
};
